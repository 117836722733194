/* .container {
	display: flex;
	flex-direction: row;
	min-height: 100%;
	overflow: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}*/

/* .chat-list {
	min-width: 240px;
	max-width: 380px;
	overflow: auto;
}

.right-panel {
	flex: 1;
	display: flex;
	flex-direction: column;
} */

.message-list {
	flex: 1;
  min-width: 140px;
  max-height: 65vh;
  height: 100%;
  overflow-y: auto;
}
/*
.input-area {
	height: 50px;
	background: red;
} */

.action-send-chat-message {
  background: #7168ef;
}
